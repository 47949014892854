import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <div className="parter-with-us-hero-container">
      <div className="hero-content">
        <p className="hero-title">Partner with us and unlock the full potential of your spa</p>
        <p className="hero-subtitle">Discover endless possibilities in the spa world by participating with us</p>
        <a href="#learn-more" className="hero-button">Learn More</a>
      </div>
    </div>
  );
};

export default Hero;
